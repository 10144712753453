/* MemberHeaderElement */

	.memberheader {
		text-align: center;
		margin-top: -20px;

		img {
			height: 250px;
			border-radius: 100%;
			margin-bottom: 25px;
			object-fit: cover;
        	object-position: center;
        	width: 250px;
		}

		div {
			font-size: 23px;
			font-weight: bold;
		}

		ul {
			cursor: default;
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
	
			li {
				display: inline-block;
				padding: 0;
	
				&:not(:last-child):after {
					content: '\00B7';
					padding: 0 0.5rem;
					font-weight: 600;
				}
	
				&:last-child {
					padding-right: 0;
				}

				span {
					margin-right: 10px;
				}
			}
		}
	}
