#notfound {
	position: relative;
	height: 100vh;
}

#notfound .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound {
	max-width: 410px;
	width: 100%;
	text-align: center;
}

.notfound .notfound-404 {
	height: 280px;
	position: relative;
	z-index: -1;
}

.notfound .notfound-404 h1 {
	font-family: 'Montserrat', sans-serif;
	font-size: 230px;
	margin: 0px;
	font-weight: 1900;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background: url('/images/bg_404.jpg') no-repeat;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: cover;
	background-position: center;
}

@media only screen and (max-width: 767px) {
	.notfound .notfound-404 {
		height: 142px;
	}

	.notfound .notfound-404 h1 {
		font-size: 112px;
	}
}