///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Box */

	.badge {
		display: inline-block;
		padding: .25em .4em;
		font-size: 75%;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: .25rem;
	}

	.badge-secondary {
		color: #fff;
		background-color: #6c757d;
	}

	.badge-warning {
		color: #212529;
		background-color: #ffc107;
	}

	.badge-danger {
		color: #fff;
		background-color: #dc3545;
	}

	.badge-success {
		color: #fff;
		background-color: #28a745;
	}

	.badge-info {
		color: #fff;
		background-color: #17a2b8;
	}