/* Publication */

	#publications {

		.tex {
			width:40px;
		}

		span {
			padding-left: 10px;
		}

		.publicationbydoctype {
			margin-bottom: 10px;

			h3 span::before {
				content: "("
			}

			h3 span {
				padding: 1px 5px 3px 5px;
				font-size: 15px;
			}

			.pdf {
				margin-left: 5px;
				margin-bottom:-5px;
				width:32px;
			}

			.tex {
				width:32px;
				margin-left: 5px;
				margin-bottom:-5px;
			}

			h3 span::after {
				content: ")"
			}

			li {
				margin-bottom: 10px;

				pre {
					margin:0 0 0 0;
					font-size:80%;
					white-space: pre-wrap;
				}
			}
		}
	}